import { useCallback, useState } from "react";
import { Login } from "../pages";

function Secure(props) {
  const [isLoggedIn, setLoggedIn] = useState(false),
    onLogin = useCallback(() => setLoggedIn(true), [setLoggedIn]);

  if (isLoggedIn) {
    return <>{props.children}</>;
  } else {
    return <Login onLogin={onLogin} />;
  }
}

export { Secure };
