import _ from "lodash";
import { Environment } from "./environment";
import { useThree } from "@react-three/fiber";
import { ContactShadows } from "@react-three/drei";
import { useEffect } from "react";
import { Fog } from "three";

function BaseShadowConfig(gl) {
  const maxTextureSize = Math.min(gl.capabilities.maxTextureSize, 2048);
  return {
    castShadow: true,
    "shadow-bias": -0.0005,
    "shadow-normalBias": 0.0025,
    "shadow-mapSize": [maxTextureSize, maxTextureSize],
    //"shadow-blurSamples": 8,
    //"shadow-radius": 2,
  };
}

function Studio() {
  const { scene, gl } = useThree(),
    baseIntensity = 12.5;

  useEffect(() => {
    if (!_.isNil(scene)) {
      scene.fog = new Fog(0xffffff, 10, 50);
    }
  }, [scene]);

  return (
    <group>
      <spotLight
        color={0xfff9fb}
        position={[0.75, 1.5, 3]}
        penumbra={0.2}
        angle={(60 * Math.PI) / 180}
        intensity={baseIntensity}
        {...BaseShadowConfig(gl)}
        shadow-radius={3}
        shadow-blurSamples={6}
      />
      <spotLight
        color={0xfff3ef}
        position={[0, 2, 0.5]}
        penumbra={0.2}
        angle={(75 * Math.PI) / 180}
        intensity={0.5 * baseIntensity}
        {...BaseShadowConfig(gl)}
        shadow-radius={5}
        shadow-blurSamples={10}
      />
      <directionalLight
        color={0xffefe6}
        position={[2.5, 0.75, -1]}
        intensity={0.0625 * baseIntensity}
        shadow-radius={10}
        shadow-blurSamples={20}
      />
      <directionalLight
        color={0xffefe6}
        position={[-2.5, 0.75, -1]}
        intensity={0.0625 * baseIntensity}
        shadow-radius={10}
        shadow-blurSamples={20}
      />
      <Environment envId="studio02" intensity={0.125 * baseIntensity} />
      <ContactShadows blur={0.5} opacity={0.5} />
    </group>
  );
}

export { Studio };
