import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Secure } from "./components";
import { Home } from "./pages";

const theme = createTheme({
  palette: {
    background: {
      default: "#fafafb",
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Secure>
          <Home />
        </Secure>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
