import { CircularProgress, Stack } from "@mui/material";
import _ from "lodash";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

const ACTIONS = {
  REGISTER: "register",
  UNREGISTER: "unregister",
  SET_LOADING_STATE: "setLoadingState",
};

const LoadingContext = createContext(_.identity),
  reducer = function (state, action) {
    const newState = _.clone(state),
      { type, payload } = action;
    switch (type) {
      case ACTIONS.REGISTER:
        return _.extend(newState, { [payload]: true });
      case ACTIONS.UNREGISTER:
        delete newState[payload];
        return newState;
      case ACTIONS.SET_LOADING_STATE: {
        const { uuid, loading } = payload;
        if (_.chain(newState).keys().includes(uuid).value()) {
          newState[uuid] = loading;
        }
        return newState;
      }
      default:
        return newState;
    }
  };

function Suspender(props) {
  const [state, dispatch] = useReducer(reducer, {}),
    loading = _.chain(state).values().some().value();
  return (
    <LoadingContext.Provider value={dispatch}>
      {loading && (
        <Stack
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          alignItems="center"
          justifyContent="center"
          bgcolor="common.white"
          zIndex={100}
        >
          <CircularProgress />
        </Stack>
      )}
      {props.children}
    </LoadingContext.Provider>
  );
}

function useRegisterLoader(uuid) {
  const dispatch = useContext(LoadingContext);

  useEffect(() => {
    dispatch({
      type: ACTIONS.REGISTER,
      payload: uuid,
    });
    return () => {
      dispatch({
        type: ACTIONS.UNREGISTER,
        payload: uuid,
      });
    };
  }, [uuid, dispatch]);

  return null;
}

function useLoadingStateUpdater(uuid) {
  const dispatch = useContext(LoadingContext),
    [setter, updateSetter] = useState(() => (loading) => {
      dispatch({
        type: ACTIONS.SET_LOADING_STATE,
        payload: { uuid, loading },
      });
    });

  useEffect(() => {
    updateSetter((prevSetter) => (loading) => {
      prevSetter(loading);
      dispatch({
        type: ACTIONS.SET_LOADING_STATE,
        payload: { uuid: uuid, loading: loading },
      });
    });
  }, [uuid, updateSetter, dispatch]);

  return setter;
}

export { Suspender, useRegisterLoader, useLoadingStateUpdater };
