class Session {
  #token;

  set token(token) {
    this.#token = token;
  }

  get token() {
    return this.#token;
  }

  clear() {
    this.#token = undefined;
  }
}

const instance = new Session();

export { instance as session };
