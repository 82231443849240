import { useFrame } from "@react-three/fiber";
import _ from "lodash";
import { forwardRef, useRef } from "react";
import { Box3, Vector3 } from "three";

const Center = forwardRef(function Center(props, ref) {
  const fallbackRef = useRef(),
    passRef = ref ?? fallbackRef,
    childrenGroupRef = useRef(),
    { top, bottom, left, right, front, back } = props,
    passProps = _.omit(props, [
      "top",
      "bottom",
      "left",
      "right",
      "front",
      "back",
      "children",
    ]);

  useFrame(() => {
    if (
      childrenGroupRef.current === undefined ||
      passRef.current === undefined
    ) {
      return;
    }
    const parentGroup = passRef.current,
      childrenGroup = childrenGroupRef.current,
      boundingBox = new Box3().setFromObject(childrenGroup, true);
    boundingBox.applyMatrix4(parentGroup.matrix.clone().invert());

    const boundingBoxCenter = boundingBox.getCenter(new Vector3()),
      size = boundingBox.getSize(new Vector3()),
      { min, max } = boundingBox,
      transform = boundingBoxCenter.clone();

    if (size.length() === 0) {
      return;
    }

    if (Boolean(top)) {
      transform.setY(min.y);
    } else if (Boolean(bottom)) {
      transform.setY(max.y);
    }

    if (Boolean(left)) {
      transform.setX(max.x);
    } else if (Boolean(right)) {
      transform.setX(min.x);
    }

    if (Boolean(front)) {
      transform.setZ(min.z);
    } else if (Boolean(back)) {
      transform.setZ(max.z);
    }

    if (transform.length() !== 0) {
      childrenGroup.position.sub(transform.clone());
      childrenGroup.needsUpdate = true;
    }
  });

  return (
    <group ref={passRef} {...passProps}>
      <group ref={childrenGroupRef}>{props.children}</group>
    </group>
  );
});

export { Center };
