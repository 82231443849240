import _ from "lodash";

class ResourceLoader {
  #resources = new Map();

  load(Loader, resource, extend) {
    const loader = new Loader();

    if (_.isFunction(extend)) {
      extend(loader);
    }

    if (this.#resources.has(resource)) {
      return Promise.resolve(this.#resources.get(resource));
    } else {
      return loader.loadAsync(resource).then((result) => {
        this.#resources.set(resource, result);
        return result;
      });
    }
  }
}

const instance = new ResourceLoader();

export default instance;
