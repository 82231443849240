import { Box } from "@mui/material";
import Viewer from "../components/viewer";

function Home() {
  return (
    <Box height="100vh" bgcolor="common.white">
      <Viewer />
    </Box>
  );
}

export { Home };
