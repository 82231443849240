import _ from "lodash";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Collapse as MUICollapse,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  Button,
  Slider,
} from "@mui/material";
import { useCallback, useState } from "react";
import { ColorPicker } from "@wellbees/color-picker-input";
import { FT_MATERIALS, MAIN_MATERIALS, MODELS } from "./enums";
import { MenuItem } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function getMaterialGroup(groups, materialId) {
  return _.find(groups, (group) =>
    _.chain(group.materials).map("id").includes(materialId).value()
  );
}

function SceneControls(props) {
  const [activeSection, setActiveSection] = useState(undefined),
    { value } = props,
    { useAo, colorMain, matMain, matFt, item, specularMain, specularFt } =
      value ?? {},
    matGroupKendo = getMaterialGroup(MAIN_MATERIALS, matMain),
    matGroupFT = getMaterialGroup(FT_MATERIALS, matFt),
    model = _.find(MODELS, (m) =>
      _.chain(m.versions).map("id").includes(item).value()
    ),
    onChange = useCallback(
      (value) => {
        if (_.isFunction(props.onChange)) {
          props.onChange(value);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.onChange]
    ),
    onAoChange = useCallback(
      (e) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ useAo: e.target.checked })
          .value();
        onChange(newValue);
      },
      [onChange, value]
    ),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    onKendoColorChange = useCallback(
      _.debounce((e) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ colorMain: e })
          .value();
        onChange(newValue);
      }, 200),
      [onChange, value]
    ),
    onKendoMatChange = useCallback(
      (e, mat) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ matMain: mat.id })
          .value();
        onChange(newValue);
      },
      [onChange, value]
    ),
    onKendoMatTypeChange = useCallback(
      (e) => {
        const value = e.target.value,
          matGroup = _.find(MAIN_MATERIALS, { id: value });
        if (!_.chain(matGroup.materials).map("id").includes(matMain).value()) {
          onKendoMatChange(e, _.first(matGroup.materials));
        }
      },
      [onKendoMatChange, matMain]
    ),
    onSpecularMainChange = useCallback(
      (e, sliderValue) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ specularMain: sliderValue })
          .value();
        onChange(newValue);
      },
      [onChange, value]
    ),
    onFTMatChange = useCallback(
      (e) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ matFt: e.target.value })
          .value();
        onChange(newValue);
      },
      [onChange, value]
    ),
    onFTMatTypeChange = useCallback(
      (e) => {
        const value = e.target.value,
          matGroup = _.find(FT_MATERIALS, { id: value });
        if (!_.chain(matGroup.materials).map("id").includes(matFt).value()) {
          onFTMatChange({ target: { value: _.first(matGroup.materials).id } });
        }
      },
      [onFTMatChange, matFt]
    ),
    onSpecularFtChange = useCallback(
      (e, sliderValue) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ specularFt: sliderValue })
          .value();
        onChange(newValue);
      },
      [onChange, value]
    ),
    onVersionChange = useCallback(
      (e, version) => {
        const newValue = _.chain(value ?? {})
          .clone()
          .extend({ item: version.id })
          .value();
        onChange(newValue);
      },
      [value, onChange]
    ),
    onModelChange = useCallback(
      (e) => {
        const value = e.target.value,
          model = _.find(MODELS, { id: value });
        if (!_.chain(model.versions).map("id").includes(item).value()) {
          onVersionChange(e, _.first(model.versions));
        }
      },
      [onVersionChange, item]
    ),
    onCollapseToggle = useCallback(
      (index, active) => {
        setActiveSection(active ? index : undefined);
      },
      [setActiveSection]
    );

  return (
    <Box position="fixed" m={2} zIndex={101}>
      <Card style={{ overflow: "visible" }}>
        <CardContent>
          <Stack gap={2}>
            <Typography variant="h6">Opzioni</Typography>
            <FormControlLabel
              control={
                <Switch
                  value={Boolean(useAo)}
                  defaultChecked
                  onChange={onAoChange}
                />
              }
              label="Occlusione ambientale"
            />
            <Collapse
              index={0}
              active={activeSection === 0}
              onClick={onCollapseToggle}
              title="Modello e versione"
            >
              <Stack gap={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Modello</InputLabel>
                  <Select
                    value={model.id}
                    onChange={onModelChange}
                    label="Modello"
                  >
                    {_.map(MODELS, (group, index) => {
                      return (
                        <MenuItem key={index} value={group.id}>
                          {group.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    size="small"
                    disableClearable
                    options={model.versions}
                    value={_.find(model.versions, { id: item })}
                    onChange={onVersionChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Versione" />
                    )}
                  />
                </FormControl>
              </Stack>
            </Collapse>
            <Collapse
              index={1}
              active={activeSection === 1}
              onClick={onCollapseToggle}
              title="Rivestimento"
            >
              <Stack gap={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    value={matGroupKendo.id}
                    onChange={onKendoMatTypeChange}
                    label="Tipo"
                  >
                    {_.map(MAIN_MATERIALS, (group, index) => {
                      return (
                        <MenuItem key={index} value={group.id}>
                          {group.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <Autocomplete
                    size="small"
                    disableClearable
                    options={matGroupKendo.materials}
                    value={_.find(matGroupKendo.materials, { id: matMain })}
                    onChange={onKendoMatChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Colore" />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth size="small">
                  <ColorPicker
                    fullWidth
                    colorShowType="circle"
                    pickerType="chrome"
                    inputType="mui"
                    size="small"
                    label="Colore base"
                    value={colorMain}
                    onChange={onKendoColorChange}
                  />
                </FormControl>
                <Box marginX={1}>
                  <Typography variant="caption" color="text.secondary">
                    Riflessi speculari
                  </Typography>
                  <Slider
                    value={specularMain}
                    onChange={onSpecularMainChange}
                    size="small"
                    min={0}
                    max={1}
                    step={0.01}
                  />
                </Box>
              </Stack>
            </Collapse>
            <Collapse
              index={2}
              active={activeSection === 2}
              onClick={onCollapseToggle}
              title="Piede"
            >
              <Stack gap={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Tipo</InputLabel>
                  <Select
                    value={matGroupFT.id}
                    onChange={onFTMatTypeChange}
                    label="Tipo"
                  >
                    {_.map(FT_MATERIALS, (group, index) => {
                      return (
                        <MenuItem key={index} value={group.id}>
                          {group.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small">
                  <InputLabel>Colore</InputLabel>
                  <Select value={matFt} onChange={onFTMatChange} label="Colore">
                    {_.map(matGroupFT.materials, (group, index) => {
                      return (
                        <MenuItem key={index} value={group.id}>
                          {group.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Box marginX={1}>
                  <Typography variant="caption" color="text.secondary">
                    Riflessi speculari
                  </Typography>
                  <Slider
                    value={specularFt}
                    onChange={onSpecularFtChange}
                    size="small"
                    min={0}
                    max={1}
                    step={0.01}
                  />
                </Box>
              </Stack>
            </Collapse>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
}

function Collapse(props) {
  const { title, active, index } = props,
    onClick = useCallback(() => {
      if (_.isFunction(props.onClick)) {
        props.onClick(index, !active);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onClick, active, index]);
  return (
    <Stack>
      <Button
        endIcon={active ? <ExpandLess /> : <ExpandMore />}
        onClick={onClick}
        sx={{ justifyContent: "space-between" }}
        variant="outlined"
      >
        {title}
      </Button>
      <MUICollapse in={active}>
        <Box mt={2}>{props.children}</Box>
      </MUICollapse>
    </Stack>
  );
}

export { SceneControls };
