const MAIN_MATERIALS = [
  {
    id: "leather",
    label: "Pelle",
    materials: [
      {
        id: "15C1_Lemans",
        label: "Lemans 15C1",
      },
      {
        id: "15C3_Lemans",
        label: "Lemans 15C3",
      },
      {
        id: "15C4_Lemans",
        label: "Lemans 15C4",
      },
      {
        id: "15C5_Lemans",
        label: "Lemans 15C5",
      },
      {
        id: "15CB_Lemans",
        label: "Lemans 15CB",
      },
      {
        id: "15CI_Lemans",
        label: "Lemans 15CI",
      },
      {
        id: "15CQ_Lemans",
        label: "Lemans 15CQ",
      },
      {
        id: "15CW_Lemans",
        label: "Lemans 15CW",
      },
      {
        id: "15D2_Lemans",
        label: "Lemans 15D2",
      },
      {
        id: "15D3_Lemans",
        label: "Lemans 15D3",
      },
      {
        id: "15D4_Lemans",
        label: "Lemans 15D4",
      },
      {
        id: "15D5_Lemans",
        label: "Lemans 15D5",
      },
      {
        id: "15D6_Lemans",
        label: "Lemans 15D6",
      },
      {
        id: "15D7_Lemans",
        label: "Lemans 15D7",
      },
      {
        id: "15D8_Lemans",
        label: "Lemans 15D8",
      },
      {
        id: "15E5_Lemans",
        label: "Lemans 15E5",
      },
      {
        id: "15E6_Lemans",
        label: "Lemans 15E6",
      },
      {
        id: "15E7_Lemans",
        label: "Lemans 15E7",
      },
      {
        id: "15E8_Lemans",
        label: "Lemans 15E8",
      },
      {
        id: "15E9_Lemans",
        label: "Lemans 15E9",
      },
      {
        id: "15F1_Lemans",
        label: "Lemans 15F1",
      },
      {
        id: "15F2_Lemans",
        label: "Lemans 15F2",
      },
      {
        id: "15F3_Lemans",
        label: "Lemans 15F3",
      },
      {
        id: "1535_Lemans",
        label: "Lemans 1535",
      },
      {
        id: "1571_Lemans",
        label: "Lemans 1571",
      },
      {
        id: "1572_Lemans",
        label: "Lemans 1572",
      },
      {
        id: "1575_Lemans",
        label: "Lemans 1575",
      },
      {
        id: "1576_Lemans",
        label: "Lemans 1576",
      },
      {
        id: "1581_Lemans",
        label: "Lemans 1581",
      },
      {
        id: "1582_Lemans",
        label: "Lemans 1582",
      },
      {
        id: "1583_Lemans",
        label: "Lemans 1583",
      },
      {
        id: "25_status",
        label: "Pelle 25 Status",
      },
      {
        id: "30_Misterbianco",
        label: "Pelle 30 Misterbianco",
      },
      {
        id: "30_Stromboli",
        label: "Pelle 30 Stromboli",
      },
      {
        id: "40_Siena",
        label: "Pelle 40 Siena",
      },
      {
        id: "60_Cassidy",
        label: "Pelle 60 Cassidy",
      },
    ],
  },
  {
    id: "fabric",
    label: "Tessuto",
    materials: [
      {
        id: "70_Rizzoli_70008501",
        label: "Rizzoli 70008501",
      },
      {
        id: "70_Rizzoli_70008502",
        label: "Rizzoli 70008502",
      },
      {
        id: "70_Rizzoli_70008503",
        label: "Rizzoli 70008503",
      },
      {
        id: "70_Rizzoli_70008504",
        label: "Rizzoli 70008504",
      },
      {
        id: "70_Rizzoli_70008505",
        label: "Rizzoli 70008505",
      },
      {
        id: "70_Rizzoli_70008506",
        label: "Rizzoli 70008506",
      },
      {
        id: "70_Rizzoli_70008509",
        label: "Rizzoli 70008509",
      },
      {
        id: "70_Rizzoli_70008510",
        label: "Rizzoli 70008510",
      },
      {
        id: "70_Rizzoli_70008511",
        label: "Rizzoli 70008511",
      },
      {
        id: "70_Rizzoli_70008512",
        label: "Rizzoli 70008512",
      },
      {
        id: "70_Sparano_70008401",
        label: "Sparano 70008401",
      },
      {
        id: "70_Sparano_70008402",
        label: "Sparano 70008402",
      },
      {
        id: "70_Sparano_70008403",
        label: "Sparano 70008403",
      },
      {
        id: "70_Sparano_70008404",
        label: "Sparano 70008404",
      },
      {
        id: "70_Sparano_70008405",
        label: "Sparano 70008405",
      },
      {
        id: "70_Sparano_70008406",
        label: "Sparano 70008406",
      },
      {
        id: "83_BOTTEGHE_83011501",
        label: "Botteghe 83011501",
      },
      {
        id: "83_BOTTEGHE_83011502",
        label: "Botteghe 83011502",
      },
      {
        id: "83_BOTTEGHE_83011503",
        label: "Botteghe 83011503",
      },
      {
        id: "83_BOTTEGHE_83011504",
        label: "Botteghe 83011504",
      },
      {
        id: "83_BOTTEGHE_83011505",
        label: "Botteghe 83011505",
      },
      {
        id: "83_BOTTEGHE_83011506",
        label: "Botteghe 83011506",
      },
      {
        id: "83_BOTTEGHE_83011507",
        label: "Botteghe 83011507",
      },
      {
        id: "86_Lagrange_86005901",
        label: "Lagrange 86005901",
      },
      {
        id: "86_Lagrange_86005902",
        label: "Lagrange 86005902",
      },
      {
        id: "86_Lagrange_86005903",
        label: "Lagrange 86005903",
      },
      {
        id: "86_Lagrange_86005904",
        label: "Lagrange 86005904",
      },
      {
        id: "86_Lagrange_86005905",
        label: "Lagrange 86005905",
      },
      {
        id: "86_Lagrange_86005906",
        label: "Lagrange 86005906",
      },
      {
        id: "86_Lagrange_86005907",
        label: "Lagrange 86005907",
      },
      {
        id: "86_Lagrange_86005908",
        label: "Lagrange 86005908",
      },
      {
        id: "86_Viaroma_86005701",
        label: "Viaroma 86005701",
      },
      {
        id: "86_Viaroma_86005702",
        label: "Viaroma 86005702",
      },
      {
        id: "86_Viaroma_86005703",
        label: "Viaroma 86005703",
      },
      {
        id: "86_Viaroma_86005704",
        label: "Viaroma 86005704",
      },
      {
        id: "86_Viaroma_86005705",
        label: "Viaroma 86005705",
      },
      {
        id: "86_Viaroma_86005706",
        label: "Viaroma 86005706",
      },
      {
        id: "86_Viaroma_86005707",
        label: "Viaroma 86005707",
      },
      {
        id: "88_Delfino_88005201",
        label: "Delfino 88005201",
      },
      {
        id: "88_Delfino_88005202",
        label: "Delfino 88005202",
      },
      {
        id: "88_Delfino_88005203",
        label: "Delfino 88005203",
      },
      {
        id: "88_Delfino_88005204",
        label: "Delfino 88005204",
      },
      {
        id: "88_Delfino_88005205",
        label: "Delfino 88005205",
      },
      {
        id: "88_Farini_88005701",
        label: "Farini 88005701",
      },
      {
        id: "88_Farini_88005702",
        label: "Farini 88005702",
      },
    ],
  },
];

const FT_MATERIALS = [
  {
    id: "wood",
    label: "Legno",
    materials: [
      {
        id: "W003_Rovere_Affumicato",
        label: "Rovere Affumicato",
      },
      {
        id: "W004_Noce",
        label: "Noce",
      },
      {
        id: "W013_Noce_Olivato",
        label: "Noce Olivato",
      },
      {
        id: "W016_Frassino_Affumicato",
        label: "Frassino Affumicato",
      },
    ],
  },
  {
    id: "metal",
    label: "Metallo",
    materials: [
      {
        id: "metal01",
        label: "Metallo Lucido",
      },
      {
        id: "metal02",
        label: "Metallo Spazzolato",
      },
    ],
  },
];

const MODELS = [
  {
    id: "2826",
    label: "Borghese",
    versions: [
      {
        id: "2826_Borghese_005",
        label: "Borghese 005",
      },
      {
        id: "2826_Borghese_009",
        label: "Borghese 009",
      },
      {
        id: "2826_Borghese_010",
        label: "Borghese 010",
      },
      {
        id: "2826_Borghese_016",
        label: "Borghese 016",
      },
      {
        id: "2826_Borghese_018",
        label: "Borghese 018",
      },
      {
        id: "2826_Borghese_047",
        label: "Borghese 047",
      },
      {
        id: "2826_Borghese_200",
        label: "Borghese 200",
      },
      {
        id: "2826_Borghese_277",
        label: "Borghese 277",
      },
      {
        id: "2826_Borghese_480",
        label: "Borghese 480",
      },
      {
        id: "2826_Borghese_555",
        label: "Borghese 555",
      },
      {
        id: "2826_Borghese_559",
        label: "Borghese 559",
      },
    ],
  },
  {
    id: "2828",
    label: "Diesis",
    versions: [
      {
        id: "2828_Diesis_000",
        label: "Diesis 000",
      },
      {
        id: "2828_Diesis_001",
        label: "Diesis 001",
      },
      {
        id: "2828_Diesis_003",
        label: "Diesis 003",
      },
      {
        id: "2828_Diesis_004",
        label: "Diesis 004",
      },
      {
        id: "2828_Diesis_005",
        label: "Diesis 005",
      },
      {
        id: "2828_Diesis_009",
        label: "Diesis 009",
      },
      {
        id: "2828_Diesis_010",
        label: "Diesis 010",
      },
      {
        id: "2828_Diesis_016",
        label: "Diesis 016",
      },
      {
        id: "2828_Diesis_028",
        label: "Diesis 028",
      },
      {
        id: "2828_Diesis_030",
        label: "Diesis 030",
      },
      {
        id: "2828_Diesis_047",
        label: "Diesis 047",
      },
      {
        id: "2828_Diesis_072",
        label: "Diesis 072",
      },
      {
        id: "2828_Diesis_082",
        label: "Diesis 082",
      },
      {
        id: "2828_Diesis_098",
        label: "Diesis 098",
      },
      {
        id: "2828_Diesis_146",
        label: "Diesis 146",
      },
      {
        id: "2828_Diesis_154",
        label: "Diesis 154",
      },
      {
        id: "2828_Diesis_182",
        label: "Diesis 182",
      },
      {
        id: "2828_Diesis_193",
        label: "Diesis 193",
      },
      {
        id: "2828_Diesis_264",
        label: "Diesis 264",
      },
      {
        id: "2828_Diesis_268",
        label: "Diesis 268",
      },
      {
        id: "2828_Diesis_272",
        label: "Diesis 272",
      },
      {
        id: "2828_Diesis_291",
        label: "Diesis 291",
      },
      {
        id: "2828_Diesis_324",
        label: "Diesis 324",
      },
      {
        id: "2828_Diesis_328",
        label: "Diesis 328",
      },
      {
        id: "2828_Diesis_351",
        label: "Diesis 351",
      },
      {
        id: "2828_Diesis_352",
        label: "Diesis 352",
      },
      {
        id: "2828_Diesis_360",
        label: "Diesis 360",
      },
      {
        id: "2828_Diesis_377",
        label: "Diesis 377",
      },
      {
        id: "2828_Diesis_400",
        label: "Diesis 400",
      },
      {
        id: "2828_Diesis_446",
        label: "Diesis 446",
      },
      {
        id: "2828_Diesis_450",
        label: "Diesis 450",
      },
    ],
  },
  {
    id: "2907",
    label: "Fidelio",
    versions: [
      {
        id: "2907_Fidelio_003",
        label: "Fidelio 003",
      },
      {
        id: "2907_Fidelio_005",
        label: "Fidelio 005",
      },
      {
        id: "2907_Fidelio_009",
        label: "Fidelio 009",
      },
      {
        id: "2907_Fidelio_029",
        label: "Fidelio 029",
      },
      {
        id: "2907_Fidelio_032",
        label: "Fidelio 032",
      },
      {
        id: "2907_Fidelio_072",
        label: "Fidelio 072",
      },
      {
        id: "2907_Fidelio_088",
        label: "Fidelio 088",
      },
      {
        id: "2907_Fidelio_154",
        label: "Fidelio 154",
      },
      {
        id: "2907_Fidelio_182",
        label: "Fidelio 182",
      },
      {
        id: "2907_Fidelio_193",
        label: "Fidelio 193",
      },
      {
        id: "2907_Fidelio_216",
        label: "Fidelio 216",
      },
      {
        id: "2907_Fidelio_272",
        label: "Fidelio 272",
      },
      {
        id: "2907_Fidelio_291",
        label: "Fidelio 291",
      },
      {
        id: "2907_Fidelio_338",
        label: "Fidelio 338",
      },
      {
        id: "2907_Fidelio_346",
        label: "Fidelio 346",
      },
      {
        id: "2907_Fidelio_446",
        label: "Fidelio 446",
      },
      {
        id: "2907_Fidelio_450",
        label: "Fidelio 450",
      },
      {
        id: "2907_Fidelio_560",
        label: "Fidelio 560",
      },
      {
        id: "2907_Fidelio_715",
        label: "Fidelio 715",
      },
      {
        id: "2907_Fidelio_A54",
        label: "Fidelio A54",
      },
      {
        id: "2907_Fidelio_A82",
        label: "Fidelio A82",
      },
      {
        id: "2907_Fidelio_A93",
        label: "Fidelio A93",
      },
      {
        id: "2907_Fidelio_C38",
        label: "Fidelio C38",
      },
      {
        id: "2907_Fidelio_C46",
        label: "Fidelio C46",
      },
      {
        id: "2907_Fidelio_D46",
        label: "Fidelio D46",
      },
      {
        id: "2907_Fidelio_D50",
        label: "Fidelio D50",
      },
      {
        id: "2907_Fidelio_E60",
        label: "Fidelio E60",
      },
    ],
  },
];

export { MAIN_MATERIALS, FT_MATERIALS, MODELS };
