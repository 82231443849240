import _ from "lodash";
import { forwardRef, useEffect, useRef } from "react";
import { useResourceLoader } from "./loader";
import { FrontSide, NoColorSpace, RepeatWrapping, TextureLoader } from "three";
import { getResourceUrl } from "../../utils";

function postProcessTexture(texture, repeat) {
  texture.wrapS = texture.wrapT = RepeatWrapping;
  texture.repeat.set(repeat, repeat);
  texture.needsUpdate = true;
  return texture.clone();
}

const Material = forwardRef(function Material(props, ref) {
  const { materialId, repeat, fileExt = "jpg" } = props,
    passProps = _.omit(props, ["materialId", "repeat", "fileExt"]),
    fallbackRef = useRef(),
    passRef = ref ?? fallbackRef,
    map = useResourceLoader(
      TextureLoader,
      getResourceUrl(`materials/${materialId}/${materialId}_Albedo.${fileExt}`),
      {
        fallbackOnError: true,
        onLoad: (texture) => postProcessTexture(texture, repeat),
      },
      [materialId, repeat]
    ),
    normalMap = useResourceLoader(
      TextureLoader,
      getResourceUrl(`materials/${materialId}/${materialId}_Normal.${fileExt}`),
      {
        fallbackOnError: true,
        onLoad: (texture) => postProcessTexture(texture, repeat),
      },
      [materialId, repeat]
    ),
    roughMetMap = useResourceLoader(
      TextureLoader,
      getResourceUrl(
        `materials/${materialId}/${materialId}_Roughness.${fileExt}`
      ),
      {
        fallbackOnError: true,
        onLoad: (texture) => postProcessTexture(texture, repeat),
      },
      [materialId, repeat]
    );

  //Workaround because somewhere threejs is overriding texture colorSpace upon loading
  useEffect(() => {
    _.chain([normalMap, roughMetMap])
      .filter((texture) => !_.isNil(texture))
      .each((texture) => {
        texture.colorSpace = NoColorSpace;
        texture.needsUpdate = true;
      })
      .value();
  }, [normalMap, roughMetMap]);

  useEffect(() => {
    passRef.current && (passRef.current.needsUpdate = true);
  }, [map, normalMap, roughMetMap, passRef]);

  return (
    <meshPhysicalMaterial
      ref={passRef}
      map={map}
      normalMap={normalMap}
      roughness={_.isNil(roughMetMap) ? 0.5 : 1}
      roughnessMap={roughMetMap}
      metalness={_.isNil(roughMetMap) ? 0 : 1}
      metalnessMap={roughMetMap}
      fog={false}
      side={FrontSide}
      //shadowSide={DoubleSide}
      {...passProps}
    />
  );
});

export { Material };
