import _ from "lodash";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginDataSchema } from "../schemas";
import * as API from "../api";

function Login(props) {
  const { onLogin } = props,
    [loginError, setLoginError] = useState(),
    {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(loginDataSchema),
      defaultValues: {
        username: "natuzzi-user-7392",
        password: "R#p7Qa9L",
      },
    }),
    onSubmit = useCallback(
      (data) => {
        API.login(data)
          .then(() => {
            if (_.isFunction(onLogin)) {
              onLogin();
            }
          })
          .catch((error) => setLoginError(error?.message ?? String(error)));
      },
      [onLogin, setLoginError]
    );

  return (
    <Container fixed maxWidth="xs">
      <Stack py={2} minHeight="100vh" justifyContent="center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h5">Login</Typography>
                <TextField
                  id="username"
                  label="Username"
                  {...register("username")}
                  error={!_.isNil(errors?.username)}
                  helperText={errors?.username?.message}
                />
                <TextField
                  id="password"
                  label="Password"
                  {...register("password")}
                  error={!_.isNil(errors?.password)}
                  helperText={errors?.password?.message}
                  type="password"
                />
                {!_.isNil(loginError) && (
                  <Alert severity="error">{loginError}</Alert>
                )}
              </Stack>
            </CardContent>
            <CardActions>
              <Button type="submit">Login</Button>
            </CardActions>
          </Card>
        </form>
      </Stack>
    </Container>
  );
}

export { Login };
