import _ from "lodash";
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useState,
} from "react";
import { useResourceLoader } from "./loader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { NoColorSpace, TextureLoader } from "three";
import { getResourceUrl } from "../../utils";

const Item = forwardRef(function Item(props, ref) {
  const { itemId } = props,
    passProps = _.omit(props, ["itemId"]),
    [geometries, setGeometries] = useState([]),
    objGroup = useResourceLoader(
      OBJLoader,
      getResourceUrl(`models/${itemId}/model.obj`),
      undefined,
      itemId
    ),
    aoMap = useResourceLoader(
      TextureLoader,
      getResourceUrl(`models/${itemId}/ao.jpg`),
      {
        fallbackOnError: true,
        onLoad: (texture) => {
          texture.colorSpace = NoColorSpace;
          return texture;
        },
      },
      itemId
    );

  useEffect(() => {
    if (!_.isNil(objGroup)) {
      const geometries = [];
      objGroup.traverse((obj) => {
        if (obj.isMesh) {
          geometries.push(obj.geometry);
        }
      });
      setGeometries(geometries);
    }
  }, [objGroup, setGeometries]);

  return (
    <group ref={ref} {...passProps}>
      {_.map(geometries, (geometry, index) => (
        <mesh key={index} geometry={geometry} castShadow receiveShadow>
          {Children.map(props.children ?? [], (child, index) => {
            return isValidElement(child)
              ? cloneElement(child, { aoMap: aoMap, index: index })
              : child;
          })}
        </mesh>
      ))}
    </group>
  );
});

export { Item };
