import _ from "lodash";
import { CubeTextureLoader, NoColorSpace, PMREMGenerator } from "three";
import { useResourceLoader } from "./loader";
import { useFrame, useThree } from "@react-three/fiber";
import { getResourceUrl } from "../../utils";

function Environment(props) {
  const { envId, intensity = 1 } = props,
    { scene, gl } = useThree();
  useResourceLoader(
    CubeTextureLoader,
    [
      [
        getResourceUrl(`env/${envId}/px.jpg`),
        getResourceUrl(`env/${envId}/nx.jpg`),
        getResourceUrl(`env/${envId}/py.jpg`),
        getResourceUrl(`env/${envId}/ny.jpg`),
        getResourceUrl(`env/${envId}/pz.jpg`),
        getResourceUrl(`env/${envId}/nz.jpg`),
      ],
    ],
    {
      onLoad: ([texture]) => {
        const pmremGenerator = new PMREMGenerator(gl);
        const envTexture = pmremGenerator.fromCubemap(texture).texture;
        envTexture.colorSpace = NoColorSpace;
        scene.environment = envTexture;
        pmremGenerator.dispose();
      },
    },
    [scene, gl, envId]
  );

  useFrame((state) => {
    const _intensity = _.isFinite(intensity) ? intensity : 1,
      { scene } = state;
    scene.traverse((obj) => {
      if (obj.isMesh) {
        const materials = _.isArray(obj.material)
          ? obj.material
          : [obj.material];
        _.chain(materials)
          .filter({ toneMapped: true })
          .filter((m) => m.envMapIntensity !== _intensity)
          .each((m) => {
            m.envMapIntensity = _intensity;
          })
          .value();
        console.log();
      }
    });
  });

  return null;
}

export { Environment };
