import { loginDataSchema } from "../schemas";
import { API_URL } from "../config";
import { session } from "./session";

function login(data) {
  return loginDataSchema
    .validate(data)
    .then(() => {
      return fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Login failed");
      }
      return response.json();
    })
    .then((data) => {
      session.token = data.token;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

export { login };
