import _ from "lodash";
import { Canvas } from "@react-three/fiber";
import { useState } from "react";
import { BakeShadows, CameraControls } from "@react-three/drei";
import { Studio } from "./studio";
import { Item } from "./item";
import { Material } from "./material";
import { Center } from "./center";
import { SceneControls } from "./sceneControls";
import { FT_MATERIALS, MAIN_MATERIALS, MODELS } from "./enums";
import { Suspender } from "./loader";

function Viewer() {
  const [glOptions] = useState({
      physicallyCorrectLights: true,
      toneMappingExposure: 1,
    }),
    [cameraOptions] = useState({ near: 1e-2, position: [0, 1, 2] }),
    [sceneConfig, setSceneConfig] = useState({
      useAo: true,
      colorMain: "ffffff",
      matMain: _.chain(MAIN_MATERIALS)
        .map("materials")
        .first()
        .map("id")
        .first()
        .value(),
      matFt: _.chain(FT_MATERIALS)
        .map("materials")
        .first()
        .map("id")
        .first()
        .value(),
      specularMain: 1,
      specularFt: 1,
      item: _.chain(MODELS).map("versions").first().map("id").first().value(),
    }),
    { useAo, colorMain, matMain, matFt, item, specularMain, specularFt } =
      sceneConfig ?? {},
    aoMapIntensity = useAo ? 1 : 0;

  return (
    <>
      <SceneControls value={sceneConfig} onChange={setSceneConfig} />
      <Suspender>
        <Canvas
          performance={{ min: 0.1, debounce: 200 }}
          gl={glOptions}
          camera={cameraOptions}
          shadows="variance"
          frameloop="demand"
        >
          <CameraControls />
          <Studio />
          <Center top>
            <Item itemId={`${item}/Main`} rotation={[0, Math.PI, 0]}>
              <Material
                color={parseInt(colorMain.replace("#", ""), 16)}
                materialId={matMain}
                repeat={20}
                aoMapIntensity={aoMapIntensity}
                specularIntensity={specularMain}
                fileExt={
                  _.chain(MAIN_MATERIALS)
                    .map("materials")
                    .flatten(1)
                    .find({ id: matMain })
                    .value().fileExt
                }
              />
            </Item>
            <Item itemId={`${item}/Ft`} rotation={[0, Math.PI, 0]}>
              <Material
                materialId={matFt}
                repeat={8}
                aoMapIntensity={aoMapIntensity}
                specularIntensity={specularFt}
                fileExt={
                  _.chain(FT_MATERIALS)
                    .map("materials")
                    .flatten(1)
                    .find({ id: matFt })
                    .value().fileExt
                }
              />
            </Item>
          </Center>
          <BakeShadows />
        </Canvas>
      </Suspender>
    </>
  );
}

export { Viewer };
